<template>
  <div class="control-item">
    <div class="control-key"><span>{{ this.keyControlInformation.key }}</span></div>
    <div class="control-description">
      <h6>{{ this.keyControlInformation.title }}</h6>
      <span>{{ this.keyControlInformation.description }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ControlItem',
  props: {
    keyControlInformation: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped>
.control-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.control-item > * {
  margin: 0;
}

.control-key {
  height: 4em;
  width: 4em;
  min-width: 4em;
  max-height: 4em;
  border: 0.063rem solid var(--white);
  border-radius: 0.313rem;
  background: rgba(255, 255, 255, 0.15);
  font-family: 'IBM Plex Mono';
  display: flex;
  justify-content: center;
  align-items: center;
}

.control-description {
  margin-left: 0.625rem;
}

.control-description span {
  font-weight: 400;
  margin: 0;
}

h6 {
  font-size: 0.875rem;
  font-weight: 700;
  margin-bottom: 0.25em;
}

span {
  font-size: 0.75rem;
  font-weight: 700;
}
</style>